<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a>
          <h1>{{ getProjectTypeName(project.projectTypeId) }}</h1>
        </div>

        <!-- boxapp body -->
        <div class="box-main">
          <Steps :page-options="pageOptions" :project="project" />

          <div class="section box-ages">
            <!-- section head -->
            <div class="head">
              <h5>PASSO 3</h5>
              <h4>Beneficiários Diretos</h4>
            </div>

            <div class="wrapper">
              <div>
                <v-layout row wrap>
                  <v-flex sm6 xs12 pr-2>
                    <v-switch
                      v-model="project.onlyDirectBeneficiaries"
                      label="Informar somente beneficiários diretos"
                      @change="changeOnlyDirectBeneficiaries()"
                      class="ma-0"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </div>

              <template v-for="ageRangeGroup in ageRangeList">
                <h5 :key="ageRangeGroup.name">{{ageRangeGroup.name}}</h5>
                <v-layout row wrap :key="ageRangeGroup.id">
                  <template v-for="ageRange in ageRangeGroup.ageRanges">
                    <v-flex :key="ageRange.id" class="item md3 sm6 xs12">
                      <div class="face">
                        <img :src="require('@/assets/images/' + ageRange.imagePath)" alt />
                      </div>
                      <span>{{ ageRange.description }}</span>
                      <div class="people-input">
                        <the-mask
                          :value="project.ageRangeList.find(x => x.ageRangeId == ageRange.id).quantity"
                          v-model.number="project.ageRangeList.find(x => x.ageRangeId == ageRange.id).quantity"
                          @input="sumDirectBeneficiaries()"
                          mask="##################"
                          :masked="false"
                          :disabled="project.onlyDirectBeneficiaries"
                          class="input-default"
                        ></the-mask>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </template>

              <BorderSeparation />
              <div>
                <v-layout row wrap>
                  <v-flex
                    :class="project.onlyDirectBeneficiaries ? 'sm3 xs12 pr-2' : 'sm6 xs12 pr-2'"
                  >
                    <InputMoney										
                      helpLabel="Automático"
                      textLabel="Beneficiários diretos"
                      place-holder="2.500"
                      v-model="project.directBeneficiaries"
                      :disabled="!project.onlyDirectBeneficiaries"
                      :precision="0"
                      prefix=""
                    />
                  </v-flex>
                  <v-flex sm3 xs12 pr-2 v-if="project.onlyDirectBeneficiaries">
                    <InputText
                      ref="directBeneficiariesDescriptionInput"
                      type="text"
                      textLabel="Descrição dos beneficiários"
                      v-model="project.directBeneficiariesDescription"
                      :required="false"
                    />
                  </v-flex>
                  <v-flex sm6 xs12>
                  <InputMoney
                    helpLabel="Quantidade estimada"
										v-model="project.indirectBeneficiaries"
                    place-holder="3000"
										textLabel="Beneficiários indiretos"
										:precision="0"
                    prefix=""
									/>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex sm6 xs12 my-3>
                    <v-label>
                      Os beneficiários serão os mesmos
                      <br />ou irão variar ao longo do projeto?
                    </v-label>
                    <InputSelect
                      ref="sameBeneficiariesInput"
                      v-model="project.sameBeneficiaries"
                      :items="beneficiaries"
                      valueAttribute="same"
                      textAttribute="name"
                      invalidInputMessage="Selecione se os beneficiários serão os mesmos ou não"
                      @input="changeSameBeneficiaries($event)"
                      @validationStatusChanged="validateStep"
                      :validation="validateSameBeneficiaries"
                      :useAnotherDefaultValue="true"
                      unselectedValue="null"
                    ></InputSelect>
                  </v-flex>
                  <v-flex xs12 mb-2>
                    <div>
                      Mesmos beneficiários:
                      <v-label>mesmas pessoas serão atendidas ao longo de todo o projeto.</v-label>
                    </div>
                    <div class="mt-2">
                      Beneficiários diferentes:
                      <v-label>Pessoas diferentes serão atendidas em cada período do projeto.</v-label>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-help-spacer"></div>
      <HelpBox :text="text" />
    </v-layout>
    <FooterStep ref="footer" v-model="pageOptions" :validationMethod="isValid" :project="project" />
    <AucAlert
      ref="alertComponent"
      title="Todas as metas serão excluídas e precisarão ser criadas novamente. Você ter certeza que deseja prosseguir?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="deleteGoalsByProject"
      @cancel="cancelAlert"
    />
  </div>
</template>

<script type="plain/text">
import Steps from "@/components/Steps.vue";
import FooterStep from "@/components/FooterStep.vue";
import HelpBox from "@/components/HelpBox.vue";
import Project from "@/scripts/models/project.model";
import ProjectService from "@/scripts/services/project.service";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import AucAlert from "@/components/AucAlert.vue";
import store from "@/store.js";
export default {
  extends: ValidatableComponent,
  components: {
    HelpBox,
    Steps,
    FooterStep,
    AucAlert
  },
  props: ["pageOptions", "formValidation"],
  data() {
    return {
      switch1: false,
      originalSameBeneficiaries: null,
      text: HelpBoxText.ProjectStep3,
      project: new Project(),
      projectService: new ProjectService(),
      ageRangeList: [],
      originalAgeRangeValues: [],
      formatterHelper: new FormatterHelper(),
      beneficiaries: [
        {
          same: true,
          name: "Mesmos beneficiários"
        },
        {
          same: false,
          name: "Beneficiários diferentes"
        }
      ],
      getProjectTypeName: GetProjectTypeName,
      projectValidationStatusIndex: 3,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" },
        { pathName: Routes.app.ProjectStep0, text: "Passo 3" }
      ]
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.projectId && to.query.projectId != 0) {
      let projectService = new ProjectService();
      projectService.findById(to.query.projectId).then(function(data) {
        if (data.projectTypeId != ProjectTypes.Ticket) {
          to.query.project = data;
          next();
        } else {
          store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Essa página não está disponível para esse tipo de projeto."
          );
          next(false);
        }
      });
    } else {
      next({ name: Routes.app.ProjectStep0 });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.project.sameBeneficiaries === "null")
      this.project.sameBeneficiaries = null;
    if (!this.project.onlyDirectBeneficiaries)
      this.project.directBeneficiariesDescription = "";
    if(!this.$route.query.saved) {
			this.$route.query.saved = true;
			this.projectService.save(this.project).then(next);
		} else {
			next();
		}
  },
  created() {
    this.project = this.$route.query.project;
    this.originalSameBeneficiaries = this.project.sameBeneficiaries;
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.validationMethod = this.validate;
    this.onValidationSuccessMethod = this.setValidStep;
    this.onValidationFailMethod = this.setInvalidStep;
    this.projectService.ListAllAgeRangeGroup(this.listAllAgeRangeGroupCallback);
    this.pageOptions.changeClass("footer");
    this.pageOptions.currentPage = 3;
  },
  methods: {
    listAllAgeRangeGroupCallback(data) {
      this.ageRangeList = data;
      let tempList = [];
      if (this.project.ageRangeList) {
        for (let i = 0; i < this.ageRangeList.length; i++) {
          for (let j = 0; j < this.ageRangeList[i].ageRanges.length; j++) {
            tempList.push({
              ageRangeId: this.ageRangeList[i].ageRanges[j].id,
              quantity: 0
            });
          }
        }
        for (let i = 0; i < this.project.ageRangeList.length; i++) {
          var index = tempList.findIndex(
            x => x.ageRangeId == this.project.ageRangeList[i].ageRangeId
          );
          tempList[index] = this.project.ageRangeList[i];
        }
        this.project.ageRangeList = tempList;
        this.originalAgeRangeValues = JSON.parse(JSON.stringify(tempList));
      }
    },
    cancelAlert() {
      if (this.originalSameBeneficiaries == null)
        this.project.sameBeneficiaries = "null";
      else this.project.sameBeneficiaries = this.originalSameBeneficiaries;
    },
    deleteGoalsByProject() {
      this.saveSameBeneficiaries();
      this.projectService.deleteGoalsByProject(
        function(data) {
          if (data) {
            this.project.goalList = [];
            this.$set(this.project.stepsValidationStatus, "step5_0", false);
            this.$set(this.project.stepsValidationStatus, "step5", false);
          }
        }.bind(this),
        this.project.id
      );
    },
    changeOnlyDirectBeneficiaries(currentValue) {
      if (this.project.onlyDirectBeneficiaries) {
        this.project.ageRangeList.forEach(function(ageRange) {
          ageRange.quantity = 0;
        });
      } else {
        this.project.ageRangeList = JSON.parse(
          JSON.stringify(this.originalAgeRangeValues)
        );
        this.sumDirectBeneficiaries();
      }
    },
    changeSameBeneficiaries(event) {
      if (this.project.goalList.length > 0) {
        this.$refs.alertComponent.open();
      } else {
        this.saveSameBeneficiaries();
      }

      // if (
      //   this.project.sameBeneficiaries != "null" &&
      //   this.project.sameBeneficiaries !==
      //     String(this.originalSameBeneficiaries) &&
      //   this.project.goalList.length > 0
      // ) {
      //   this.$refs.alertComponent.open();
      // }
    },
    // changeSameBeneficiaries(event) {
    //   if (
    //     this.project.sameBeneficiaries != "null" &&
    //     this.project.sameBeneficiaries !==
    //       String(this.originalSameBeneficiaries) &&
    //     this.project.goalList.length > 0
    //   ) {
    //     this.$refs.alertComponent.open();
    //   }
    // },
    sumDirectBeneficiaries() {
      let sum = 0;
      for (let i = 0; i < this.project.ageRangeList.length; i++) {
        let currentQuantity = Number(this.project.ageRangeList[i].quantity);
        if (!Number.isNaN(currentQuantity)) sum += currentQuantity;
      }
      this.project.directBeneficiaries = sum;
      this.validateStep();
    },
    saveSameBeneficiaries() {
      var isSameBeneficiaries =
        this.project.sameBeneficiaries === "null"
          ? null
          : this.project.sameBeneficiaries;
      let valid =
        this.project.directBeneficiaries > 0 && isSameBeneficiaries != null;
      this.projectService.changeSameBeneficiaries(
        this.project.id,
        isSameBeneficiaries,
        valid
      );
    },
    validateSameBeneficiaries() {
      if (this.project.sameBeneficiaries === "null") {
        return false;
      }
      return true;
    },
    setValidStep() {
      this.$set(this.project.stepsValidationStatus, "step3", true);
    },
    setInvalidStep() {
      this.$set(this.project.stepsValidationStatus, "step3", false);
    },
    validateStep() {
      let valid = this.isValid(false);
      if (valid) this.setValidStep();
      else this.setInvalidStep();
    },
    validate(performMethods) {
      let directBeneficiaries = Number(this.project.directBeneficiaries);
      if (Number.isNaN(directBeneficiaries) || directBeneficiaries <= 0) {
        if (performMethods) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Preencha ao menos um campo dos beneficiários diretos"
          );
        }
        return false;
      }   
      return this.validateComponents(performMethods);
    }
  }
};
</script>